import React, { useMemo, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useInterval } from 'react-use'

import useStories from 'lib/useStories'
import useStoriesContext, { COUNTDOWN } from 'context/stories'

import { CircularBody, MonumentCard } from '../Text'
import { ICountdown } from './StoriesTypes'
import * as s from './Stories.module.scss'
const cn = classNames.bind(s)

export const Countdown = ({ prefix, suffix, activeStoryIndex, onCountdownEnd }: ICountdown) => {
  const { stories } = useStories()
  const { nextStoryTitle, nextStoryUid, nextStoryIndex } = useMemo(() => {
    const nextStoryIndex = (activeStoryIndex + 1) % stories.length
    return {
      nextStoryTitle: stories[nextStoryIndex].data.title,
      nextStoryUid: stories[nextStoryIndex].uid,
      nextStoryIndex,
    }
  }, [stories, activeStoryIndex])

  const { suffixPre, suffixPost } = useMemo(() => {
    const split = suffix.split('#') // # number placeholder symbol
    return { suffixPre: split?.length !== 2 ? '' : split[0], suffixPost: split?.length !== 2 ? '' : split[1] }
  }, [suffix])

  const setCountdownTime = useStoriesContext(s => s.setCountdownTime)
  const countdownTime = useStoriesContext(s => s.countdownTime)

  useInterval(
    () => {
      setCountdownTime(useStoriesContext.getState().countdownTime - 1)
    },
    countdownTime > 0 ? 1000 : null,
  )

  useEffect(() => {
    countdownTime === 0 && onCountdownEnd(nextStoryUid, nextStoryIndex, true)
  }, [setCountdownTime, countdownTime, onCountdownEnd, nextStoryUid, nextStoryIndex])

  useEffect(() => {
    return () => setCountdownTime(COUNTDOWN)
  }, [setCountdownTime])

  return (
    <div className={cn('countdown')}>
      <div>
        <CircularBody className={cn('prefix')}>{prefix}</CircularBody>
        <MonumentCard className={cn('nextStory')}>{nextStoryTitle}</MonumentCard>
        <CircularBody className={cn('suffix')}>
          <span className={cn('pre')}>{suffixPre}</span>
          <span className={cn('time')}>{countdownTime}</span>
          <span className={cn('post')}>{suffixPost}</span>
        </CircularBody>
      </div>
    </div>
  )
}
