import React, { useEffect, useRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import useStoriesContext from 'context/stories'
import { CircularCaption } from 'components/ui/Text'

import { IStory } from './StoriesTypes'
import * as s from './Stories.module.scss'
import Outline from './Outline'
const cn = classNames.bind(s)

const Story = ({ index, activeStoryIndex, nextStoryIndex, uid, data, onUpdateStory, listOffset }: IStory) => {
  const ref = useRef(null)

  const isActive = index === activeStoryIndex
  const isNext = index === nextStoryIndex

  const countdownTime = useStoriesContext(s => s.countdownTime)
  const showCountdown = useStoriesContext(s => s.showCountdown)

  const toX = (x: string, transition: string) => {
    // @ts-ignore
    Object.assign(ref?.current?.style, {
      transform: `translateX(${x})`,
      transition,
    })
  }

  useEffect(() => {
    const x = 100 * (activeStoryIndex + (showCountdown && nextStoryIndex === 0 ? -activeStoryIndex : 0))
    toX(`-${x}%`, `transform .5s`)
    listOffset.onChange(v => toX(`calc(-${x}% + ${v}px)`, `none`))
  }, [activeStoryIndex, showCountdown, nextStoryIndex, listOffset])

  return (
    <li ref={ref} className={cn('story', { isActive })}>
      <motion.div
        className={cn('storyInner')}
        animate={{ opacity: isActive ? 1 : 0.45 }}
        whileHover={{ opacity: 0.55 }}
        transition={{ type: 'tween', duration: 0.45 }}
      >
        <div className={cn('storyFrame')}>
          <GatsbyImage
            className={cn('image')}
            image={data.image.gatsbyImageData}
            alt={data.title}
            imgStyle={{ transition: 'none' }} // fixes blinking ( ͡° ͜ʖ ͡°)
          />
        </div>
        <div className={cn('storyTitle')}>
          <CircularCaption as='p'>{data.title}</CircularCaption>
        </div>
        <button
          onClick={() => onUpdateStory(uid, index)}
          className={cn('button')}
          tabIndex={0}
          {...(data.title ? { 'aria-label': data.title } : { tabIndex: -1 })}
        />
      </motion.div>
      {isNext && showCountdown && !!countdownTime && (
        <>
          <small
            className={cn('storyCountdown')}
            aria-live='polite'
            role='status'
            aria-label={countdownTime.toString()}
          >
            {countdownTime}
          </small>
          <Outline />
        </>
      )}
    </li>
  )
}

export default Story
