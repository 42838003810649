// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var videosWrapper = "Videos-module--videosWrapper--25L9o";
export var videos = "Videos-module--videos--3HGxk";
export var withStories = "Videos-module--withStories--3IOra";
export var video = "Videos-module--video--3nZrz";
export var isActive = "Videos-module--isActive--10Yi5";
export var isVisible = "Videos-module--isVisible--314fR";
export var isReady = "Videos-module--isReady--2OSr4";
export var videoWrapper = "Videos-module--videoWrapper--28LFT";
export var cover = "Videos-module--cover--1Y297";
export var clickable = "Videos-module--clickable--3LW3I";
export var hide = "Videos-module--hide--3UbQ2";
export var image = "Videos-module--image--2FIfn";
export var content = "Videos-module--content--1k7Fe";
export var text = "Videos-module--text--2YthM";
export var title = "Videos-module--title--3s7vI";
export var tagline = "Videos-module--tagline--3iWHM";
export var cta = "Videos-module--cta--HSY71";
export var bullets = "Videos-module--bullets--rKKGQ";
export var isClickable = "Videos-module--isClickable--zEr6x";
export var pageHeight = "Videos-module--pageHeight--Wm6BK";