import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import VideosPage from 'views/VideosPage'
import { linkResolver } from 'prismic/linkResolver'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query VideosQuery($lang: String) {
    prismicVideosPage(lang: { eq: $lang }) {
      _previewable
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
        videos {
          youtube_embed_id
          title {
            raw
            text
          }
          thumbnail {
            gatsbyImageData
            alt
          }
          subtitle {
            raw
            text
          }
          story {
            uid
          }
        }
        play_label
        countdown_prefix
        countdown_suffix
      }
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      lang
    }
  }
`

export default withPrismicPreview(VideosPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
