/**
 * Returns an object that sets the active story to start with.
 */
export const getInitialState = (stories: { uid: string }[], initialStoryUid: string, uid: string | null) => {
  const defaultState = { uid: initialStoryUid, index: 0 }
  if (!uid) return defaultState

  const index = stories.findIndex(s => s.uid === uid)
  if (index === -1) return defaultState

  return { uid, index }
}
