import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames/bind'
import { increment, ref, update } from 'firebase/database'
import { motion, useMotionValue, useViewportScroll, useElementScroll } from 'framer-motion'

import Bullets from 'components/ui/Bullets'
import useFirebaseContext from 'context/firebase'
import useUIContext from 'context/ui'
import useIsDesktop from 'lib/useIsDesktop'
import { useDatabase, useUser } from 'lib/firebase'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import { scrollToX, scrollToY } from 'lib/scrollTo'
import writeToLocalStorage from 'lib/writeToLocalStorage'

import Video from './Video'
import * as s from './Videos.module.scss'
import { VideoProps, VideosProps } from './VideosTypes'

const cn = classNames.bind(s)

const YOUTUBE_PREFIX = 'youtube-embed'
const COVER_PREFIX = 'cover'

const Videos = ({
  videos,
  initialVideo,
  playLabel,
  onVideoPlayed,
  onLastVideoViewed,
  withStories,
  shouldAutoplay,
  toAdjacentStory,
}: VideosProps) => {
  const isDesktop = useIsDesktop()
  const videosRef = useRef<HTMLDivElement>(null)
  const [isReady, setIsReady] = useState(false)
  const [interacted, setInteracted] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number>(initialVideo || 0)
  const [visibleIndex, setVisibleIndex] = useState<number>(initialVideo || 0)
  const [params, setParams] = useState<{ videoWidth: number; spacing: number; indent: number }>()
  const setViewedVoteNotification = useUIContext(s => s.setViewedVoteNotification)

  /* Firebase */
  const database = useDatabase()
  const user = useUser()
  const setEarned = useFirebaseContext(s => s.setEarnedVotes)
  const earned = useFirebaseContext(s => s.earnedVotes)

  const { scrollYProgress } = useViewportScroll()
  const { scrollXProgress } = useElementScroll(videosRef)
  const x = useMotionValue(0)
  const [width, height] = useWindowSize()

  const onXScroll = useRef<() => void>()
  const readyVideosCount = useRef(0)

  const [tiltToLeft, setTiltToLeft] = useState(false)

  // Wait for all videos to be in ready state before displaying progressBar
  const onVideoReady = useCallback(() => {
    readyVideosCount.current += 1
    if (readyVideosCount.current < videos.length) return
    setTimeout(
      () => setIsReady(true),
      width < parseInt(s.breakpointDesktop) ? 400 : 0, // adds delay on mobile to avoid text flicker
    )
  }, [videos, width])

  const onVideoPlay = useCallback(
    i => {
      setActiveIndex(i)
      setInteracted(true)
      if (onVideoPlayed) {
        onVideoPlayed()
        setTiltToLeft(false)
      }
    },
    [onVideoPlayed],
  )

  const onVideoEnd = useCallback(
    i => {
      const id = videos[i].youtube_embed_id
      const alreadyRead = readFromLocalStorage('viewedVideos').find(f => f === id)
      writeToLocalStorage(id || '', 'viewedVideos')

      /* Stop at the last video. */
      if ((i + 1) % videos.length !== 0) setActiveIndex(i + 1)
      /* Callback used to switch active story */
      if (onLastVideoViewed && i + 1 === videos.length) {
        onLastVideoViewed()
        setTiltToLeft(true)
      }

      if (!!alreadyRead || !database || !user) return
      setEarned((earned || 0) + 1)
      setViewedVoteNotification(false)
      update(ref(database), { requestedEarnedVote: increment(1) })
    },
    [videos, database, user, setEarned, earned, setViewedVoteNotification, onLastVideoViewed],
  )

  // Sets view parameters - video width, video spacing
  useEffect(() => {
    if (!videosRef.current || !videosRef.current.firstChild) return
    // @ts-ignore
    const videoWidth = videosRef.current.firstChild.clientWidth || 0
    const spacing =
      parseInt(getComputedStyle(videosRef.current).getPropertyValue('--spacing')) * 0.01 * Math.max(width, height)
    const indent =
      parseInt(getComputedStyle(videosRef.current).getPropertyValue('--indent')) * 0.01 * Math.max(width, height)

    setParams({ videoWidth, spacing, indent })
  }, [width, height, videosRef])

  // Transform videosRef horizontally on y scroll change (desktop)
  useEffect(() => {
    onXScroll.current = scrollYProgress.onChange(y => {
      if (!videosRef.current || !videos || !params) return
      const xRange =
        params.videoWidth * videos.length +
        params.spacing * (videos.length - 1) -
        (width * 0.5 - params.indent + params.videoWidth * 0.5)
      x.set(-y * xRange)
    })
    return onXScroll.current
  }, [videosRef, scrollYProgress, videos, x, params, width])

  // Imperative scrollTop or scrollLeft animation
  useEffect(() => {
    if (!videosRef.current || !params) return
    if (width >= parseInt(s.breakpointDesktop)) {
      scrollToY(document.documentElement, activeIndex * window.innerHeight, 300)
    } else {
      scrollToX(videosRef.current, activeIndex * (params.videoWidth + params.spacing), 400)
    }
  }, [height, width, activeIndex, videosRef, params])

  return (
    <>
      <motion.div
        className={cn('videosWrapper', { withStories })}
        animate={{ x: tiltToLeft ? (params ? -params.videoWidth : 0) : 0 }}
        transition={{ type: 'tween', duration: 0.5 }}
      >
        <motion.main className={cn('videos')} ref={videosRef} style={{ x }}>
          {videos.map(({ youtube_embed_id, title, subtitle, thumbnail }: VideoProps, i) => (
            <Video
              key={i}
              index={i}
              total={videos.length}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              visibleIndex={visibleIndex}
              hasInteracted={interacted}
              videoId={youtube_embed_id || null}
              thumbnail={thumbnail}
              title={title}
              subtitle={subtitle}
              onVideoReady={onVideoReady}
              onVideoPlay={onVideoPlay}
              onVideoEnd={onVideoEnd}
              playLabel={playLabel}
              embedId={getElementId(YOUTUBE_PREFIX, i)}
              coverId={getElementId(COVER_PREFIX, i)}
              shouldAutoplay={i === 0 && shouldAutoplay}
              toAdjacentStory={!withStories ? undefined : toAdjacentStory}
            />
          ))}
        </motion.main>
      </motion.div>
      <div
        className={cn('pageHeight')}
        style={{ height: `calc(${videos.length * 100} * 1vh)` } as React.CSSProperties}
      />
      <aside className={cn('bullets', { isClickable: isReady })}>
        <Bullets
          progress={isDesktop ? scrollYProgress : scrollXProgress}
          total={videos.length}
          onBulletClick={i => {
            setActiveIndex(i)
            const videoCover = getElementId(COVER_PREFIX, i)
            document.getElementById(videoCover)?.focus()
          }}
          setVisibleIndex={setVisibleIndex}
          labels={videos.map(({ title, subtitle }) => `${title.text}: ${subtitle.text}`)}
        />
      </aside>
    </>
  )
}

const getElementId = (prefix: string, index: number) => `${prefix}-${index + 1}`

export default Videos
