import create from 'zustand'

export const COUNTDOWN = 5
interface Store {
  showCountdown: boolean
  setShowCountdown: (showCountdown: boolean) => void
  countdownTime: number
  setCountdownTime: (countdownTime: number) => void
}

const useStoriesContext = create<Store>(set => ({
  showCountdown: false,
  setShowCountdown: showCountdown => set({ showCountdown }),
  countdownTime: COUNTDOWN,
  setCountdownTime: countdownTime => set({ countdownTime }),
}))

export default useStoriesContext
