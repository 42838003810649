import { useState, useLayoutEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'

const useIsTouch = () => {
  const [width] = useWindowSize()
  const [isTouch, setIsTouch] = useState<boolean>(false)
  useLayoutEffect(() => {
    setIsTouch(window.matchMedia('(pointer: coarse)').matches)
  }, [width])
  return isTouch
}

export default useIsTouch
