import React, { memo } from 'react'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import { COUNTDOWN } from 'context/stories'

import * as s from './Stories.module.scss'
const cn = classNames.bind(s)

const transition = { duration: COUNTDOWN, ease: 'linear' }

const Outline = () => (
  <div className={cn('outline')}>
    <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <motion.path
        d='M32 1C36.071 1 40.1021 1.80184 43.8632 3.35974C47.6243 4.91763 51.0417 7.20108 53.9203 10.0797C56.7989 12.9583 59.0824 16.3757 60.6403 20.1368C62.1982 23.8979 63 27.929 63 32C63 36.071 62.1982 40.1021 60.6403 43.8632C59.0824 47.6243 56.7989 51.0417 53.9203 53.9203C51.0417 56.7989 47.6243 59.0824 43.8632 60.6403C40.1021 62.1982 36.071 63 32 63C27.929 63 23.8979 62.1982 20.1368 60.6403C16.3757 59.0824 12.9583 56.7989 10.0797 53.9203C7.20107 51.0417 4.91762 47.6243 3.35973 43.8632C1.80183 40.1021 0.999998 36.071 1 32C1 27.929 1.80184 23.8979 3.35974 20.1368C4.91764 16.3757 7.20108 12.9583 10.0797 10.0797C12.9583 7.20106 16.3757 4.91762 20.1368 3.35973C23.8979 1.80183 27.929 0.999997 32 1L32 1Z'
        stroke='#FFE500'
        strokeWidth='2'
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
      />
    </svg>
  </div>
)

export default memo(Outline)
