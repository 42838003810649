import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { motion, AnimatePresence } from 'framer-motion'

import useStoriesContext from 'context/stories'
import useTheme from 'lib/useTheme'
import useStories from 'lib/useStories'
import SEO from 'components/core/SEO'
import Videos from 'components/ui/Videos'
import Stories, { Countdown } from 'components/ui/Stories'

import { getInitialState } from './utils'
import { VideosPageTypes } from './VideosPageTypes'
import * as s from './VideosPage.module.scss'

const cn = classNames.bind(s)

const VideosPage = ({ data, location }: VideosPageTypes) => {
  useTheme('dark', 'white')
  const [width, height] = useWindowSize()

  const { stories, initialStoryUid } = useStories()
  const uid = new URLSearchParams(location.search).get('uid')
  const initialState = getInitialState(stories, initialStoryUid, uid)
  const [activeStory, setActiveStory] = useState(initialState)

  const storiesRef = useRef<HTMLUListElement>(null)
  const pageWrapperRef = useRef<HTMLDivElement>(null)

  const showCountdown = useStoriesContext(s => s.showCountdown)
  const setShowCountdown = useStoriesContext(s => s.setShowCountdown)

  const [shouldAutoplay, setShouldAutplay] = useState(false)

  const toAdjacentStory = (dir: 1 | -1) => {
    const n = stories.length
    const index = (((activeStory.index + dir) % n) + n) % n
    toStory(stories[index].uid, index)
  }

  const toStory = (storyUid: string, storyIndex: number, autoplay?: boolean) => {
    setShowCountdown(false)
    setShouldAutplay(!!autoplay)
    setActiveStory({ uid: storyUid, index: storyIndex })
  }

  useLayoutEffect(() => {
    if (!storiesRef.current || !pageWrapperRef.current) return
    pageWrapperRef.current.style.setProperty(`--storiesHeight`, `${storiesRef.current.clientHeight}px`)
  }, [width, height])

  useEffect(() => {
    return () => setShowCountdown(false)
  }, [setShowCountdown])

  if (!data) return null
  const document = data.prismicVideosPage
  const { play_label, countdown_prefix, countdown_suffix, videos } = document.data

  const areStoriesSet = videos.filter((v: { story: { uid: string } }) => v.story?.uid).length
  const videosInStory = videos.filter((v: { story: { uid: string } }) => v.story?.uid === activeStory.uid)

  return (
    <>
      <SEO
        title={document.data.page_meta_title}
        description={document.data.page_meta_description}
        thumbnail={document.data.page_meta_thumbnail}
      />
      <h1 className={cn('srOnly')}>{document.data.page_meta_title}</h1>
      <div className={cn('pageWrapper')} ref={pageWrapperRef}>
        <AnimatePresence>
          <motion.div
            key={activeStory.uid}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
          >
            <Videos
              // fallback - if no videos have a story set, render all videos
              videos={!areStoriesSet ? videos : videosInStory}
              playLabel={play_label}
              /* @ts-ignore */
              initialVideo={location?.state?.initialVideo || 0}
              onVideoPlayed={!areStoriesSet ? undefined : () => setShowCountdown(false)}
              onLastVideoViewed={!areStoriesSet ? undefined : () => setShowCountdown(true)}
              withStories={!!areStoriesSet}
              shouldAutoplay={shouldAutoplay}
              toAdjacentStory={!areStoriesSet ? undefined : toAdjacentStory}
            />
          </motion.div>
        </AnimatePresence>
        {!!areStoriesSet && (
          <>
            <Stories ref={storiesRef} stories={stories} activeStoryIndex={activeStory.index} onUpdateStory={toStory} />
            {showCountdown && (
              <AnimatePresence>
                <motion.div
                  key={activeStory.uid}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.5 } }}
                  exit={{ opacity: 0 }}
                >
                  <Countdown
                    prefix={countdown_prefix}
                    suffix={countdown_suffix}
                    activeStoryIndex={activeStory.index}
                    onCountdownEnd={toStory}
                  />
                </motion.div>
              </AnimatePresence>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default VideosPage
