// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var stories = "Stories-module--stories--1SFe_";
export var isDragging = "Stories-module--isDragging--xT7RZ";
export var story = "Stories-module--story--1n6WH";
export var isActive = "Stories-module--isActive--2xaEA";
export var storyInner = "Stories-module--storyInner--1SQdJ";
export var storyFrame = "Stories-module--storyFrame--S8zMm";
export var outline = "Stories-module--outline--JxaDP";
export var storyCountdown = "Stories-module--storyCountdown--f-aW2";
export var button = "Stories-module--button--1kfXy";
export var image = "Stories-module--image--f91zR";
export var countdown = "Stories-module--countdown--2BIDQ";
export var prefix = "Stories-module--prefix--LutO7";
export var suffix = "Stories-module--suffix--2uNrz";
export var time = "Stories-module--time--2oZ8T";
export var nextStory = "Stories-module--nextStory--3RNft";