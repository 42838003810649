import React, { useCallback, useEffect, useRef, useState, VideoHTMLAttributes } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import YouTube from 'react-youtube'
import { useRafLoop } from 'react-use'
import classNames from 'classnames/bind'

import useIsTouch from 'lib/useIsTouch'

import useUIContext from 'context/ui'
import { MonumentHeading2, MonumentHashtag, CircularBody } from 'components/ui/Text'

import { VideoComponentProps } from './VideosTypes'
import * as s from './Videos.module.scss'

const cn = classNames.bind(s)

const opts = {
  // height: ¯\_(ツ)_/¯,
  // width: ¯\_(ツ)_/¯,
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    // controls: 0,
    modestbranding: 1,
    rel: 0,
    autoplay: 1,
    mute: 0,
    color: 'white',
    showInfo: 1,
  },
}

const Video = ({
  hasInteracted,
  index,
  total,
  activeIndex,
  visibleIndex,
  videoId,
  onVideoReady,
  onVideoPlay,
  onVideoEnd,
  thumbnail,
  title,
  subtitle,
  playLabel,
  embedId,
  coverId,
  setActiveIndex,
  shouldAutoplay,
  toAdjacentStory,
}: VideoComponentProps) => {
  const video = useRef<HTMLDivElement>(null)
  const cover = useRef<HTMLDivElement>(null)

  const [isReady, setIsReady] = useState(false)
  const [hideCover, setHideCover] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [hasEnded, setHasEnded] = useState(false)
  const [videoEvent, setVideoEvent] = useState<VideoHTMLAttributes>(null)
  const isTouch = useIsTouch()

  // Update progress indicator
  const updateProgressBar = useCallback(progress => {
    useUIContext.setState({ activeVideoProgress: progress })
  }, [])

  // When video is loaded from YT
  const onReady = event => {
    event.target.pauseVideo()
    setVideoEvent(event.target)
    setIsReady(true)
    onVideoReady(index)
  }

  // Play event
  const onPlay = useCallback(() => {
    setHasEnded(false)
    onVideoPlay(index, video) // parent callback
  }, [onVideoPlay, index])

  // End event
  const onEnd = () => {
    onVideoEnd(index) // parent callback
  }

  // Progress rAF loop
  const [loopStop, loopStart /*, isLoopActive*/] = useRafLoop(() => {
    const player = videoEvent.playerInfo
    const progress = player.currentTime / player.duration
    updateProgressBar(progress)
  }, false)

  // On play state change
  useEffect(() => {
    if (!videoEvent) return
    isPlaying ? loopStart() : loopStop()
    setHideCover(isPlaying || (videoEvent?.playerInfo.currentTime > 0 && isHovering))

    /* Place focus on embedded player to improve experience when navigating via keyboard */
    if (isPlaying) document.getElementById(embedId)?.focus()
  }, [isPlaying, isHovering, loopStart, loopStop, videoEvent, embedId])

  // Main video imperative handle
  const toggleVideo = useCallback(
    shouldPlay => {
      videoEvent[shouldPlay ? 'playVideo' : 'pauseVideo']()
    },
    [videoEvent],
  )

  // Autoplay - used for first video when story triggered from countdown
  useEffect(() => {
    shouldAutoplay && videoEvent && onTriggerClick()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [shouldAutoplay, videoEvent])

  // On video interact
  const onTriggerClick = useCallback(() => {
    if (!videoEvent) return
    const playing = videoEvent.playerInfo.playerState === 1
    toggleVideo(!playing)
  }, [videoEvent, toggleVideo])

  // Active vs inactive
  useEffect(() => {
    if (!videoEvent || !hasInteracted) return
    const isActive = activeIndex === index
    toggleVideo(isActive)

    // Update inactive videos ui and state
    if (!isActive) {
      videoEvent.seekTo(0)
      setHasEnded(true)
      loopStop()
    }
  }, [index, activeIndex, videoEvent, updateProgressBar, loopStop, toggleVideo, hasInteracted])

  return (
    <article
      className={cn('video', {
        isReady,
        isActive: index === activeIndex,
        isVisible: index === visibleIndex,
        isPlaying,
        hasEnded,
      })}
      ref={video}
    >
      <div className={cn('videoWrapper')}>
        <div
          className={cn('embed')}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          {...(!isTouch && !isHovering && !isPlaying && { hidden: true })}
        >
          {videoId && (
            <YouTube
              opts={opts}
              videoId={videoId}
              onReady={onReady}
              onPlay={onPlay}
              onEnd={onEnd}
              onStateChange={e => setIsPlaying(e.data === 1)}
              id={embedId}
            />
          )}
        </div>
        <div
          className={cn('cover', { hide: hideCover, clickable: !isPlaying })}
          ref={cover}
          onClick={onTriggerClick}
          onKeyDown={event => {
            if (event.code === 'Space' || event.code === 'Enter') {
              onTriggerClick()
            } else if (event.code === 'Tab' && !isPlaying) {
              /* Put next video in focus when user navigates with `Tab` */
              /* Optional previous/next story navigation */
              if (event.shiftKey) {
                if (index === 0) {
                  toAdjacentStory && toAdjacentStory(-1)
                  event.preventDefault()
                }
                setActiveIndex(index - 1)
              } else {
                if (index + 1 === total) {
                  toAdjacentStory && toAdjacentStory(1)
                  event.preventDefault()
                }
                setActiveIndex(index + 1)
              }
            }
          }}
          aria-controls={embedId}
          id={coverId}
          role='button'
          tabIndex={0}
        >
          <GatsbyImage
            className={cn('image')}
            image={thumbnail.gatsbyImageData}
            objectFit='cover'
            alt={thumbnail.alt || ''}
          />
          <div className={cn('content')}>
            <div className={cn('text')}>
              <MonumentHashtag className={cn('tagline')} as='h3'>
                {subtitle.text}
              </MonumentHashtag>
              <MonumentHeading2 className={cn('title')}>{title.text}</MonumentHeading2>
            </div>
            <CircularBody as='span' className={cn('cta')}>
              {playLabel}
            </CircularBody>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Video
