import React, { forwardRef, memo, useEffect, useRef, useState } from 'react'
import { motion, useMotionValue } from 'framer-motion'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'
import { useRafLoop } from 'react-use'

import useIsTouch from 'lib/useIsTouch'

import Story from './Story'
import { IStories, IStory } from './StoriesTypes'

import * as s from './Stories.module.scss'
const cn = classNames.bind(s)

const setDocumentCursor = (style: string | null) => {
  if (!document) return
  if (style) {
    document.documentElement.style.cursor = style
    return
  }
  document.documentElement.style.removeProperty('cursor')
}

const Stories = forwardRef<HTMLUListElement, IStories>(({ stories, activeStoryIndex, onUpdateStory }, ref) => {
  const isTouch = useIsTouch()
  const [width] = useWindowSize()

  const x = useMotionValue(0)
  const drag = useRef({ start: 0, delta: 0, lerped: 0 })
  const [isDragging, setIsDragging] = useState(false)

  // @ts-ignore
  const rect = ref?.current?.getBoundingClientRect()
  const rightEdge = (activeStoryIndex / stories.length) * rect?.width
  const leftEdge = -rect?.x - (rect?.x + rect?.width - width) + rightEdge

  // using lerp for smoother scroll-like movement on touch devices
  const [loopStop, loopStart] = useRafLoop(() => {
    drag.current.lerped += (drag.current.delta - drag.current.lerped) * 0.25
    if (Math.abs(drag.current.lerped - drag.current.delta) > 0.01) {
      x.set(drag.current.lerped)
    }
  }, false)

  useEffect(() => {
    isTouch ? loopStart() : loopStop()
  }, [isTouch, loopStart, loopStop])

  const onDragStart = (e: any) => {
    drag.current.start = (isTouch ? e.changedTouches[0].pageX : e.clientX) - drag.current.delta
    if (!isTouch) {
      setIsDragging(true)
      setDocumentCursor('grabbing')
    }
  }

  const onDrag = (e: any) => {
    let delta = (isTouch ? e.changedTouches[0].pageX : e.clientX) - drag.current.start
    delta = Math.min(Math.max(delta, leftEdge), rightEdge)
    drag.current.delta = delta
    !isTouch && x.set(drag.current.delta)
  }

  const onDragEnd = () => {
    setIsDragging(false)
    setDocumentCursor(null)
  }

  useEffect(() => {
    // reset dragging data on index change
    drag.current.delta = 0
    drag.current.lerped = 0
    if (!isTouch) {
      setIsDragging(false)
      setDocumentCursor(null)
    }
  }, [activeStoryIndex, isTouch])

  return (
    <>
      <motion.ul
        className={cn('stories', { isDragging })}
        ref={ref}
        // fade-in/out
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'tween', duration: 0.5, delay: 0.15 }}
        // drag behavior
        drag={isTouch ? false : 'x'}
        dragConstraints={{ left: 0, right: 0 }}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        dragElastic={0.05}
        // touch behavior
        onTouchStart={isTouch ? onDragStart : undefined}
        onTouchMove={isTouch ? onDrag : undefined}
      >
        {stories.map((story: IStory, index: number) => (
          <Story
            key={index}
            index={index}
            activeStoryIndex={activeStoryIndex}
            nextStoryIndex={(activeStoryIndex + 1) % stories.length}
            uid={story.uid}
            data={story.data}
            onUpdateStory={onUpdateStory}
            listOffset={x}
          />
        ))}
      </motion.ul>
    </>
  )
})

Stories.displayName = 'Stories'

export default memo(Stories)
